import React from 'react'
import DevIcon from "devicon-react-svg";

const About = () => {
  return <div id="Home">
      <div className="bg"></div>
    <div className="content">
      <a href="https://nascis.fr"><img alt="Nascis - Web Agency" src='./nascis.png' style={{height: '125px', width: '125px'}}/></a>
      <div className="title">
        <h1>DEV</h1>
        <p>We make innovative solutions based on your requirements</p>
        <div className="services">
          <DevIcon width={30} height={30} icon="npm" />
          <DevIcon width={30} height={30} icon="mongodb" />
          <DevIcon width={30} height={30} icon="react" />
          <DevIcon width={30} height={30} icon="css3" />
          <DevIcon width={30} height={30} icon="nodejs" />
          <DevIcon width={30} height={30} icon="html5" />
          <DevIcon width={30} height={30} icon="docker" />
        </div>
      </div>
    </div>  
  </div>
};

export default About;
